











































import { defineComponent } from "@vue/composition-api";
import useInfiniteScrollList from "@/apps/core/modules/useInfiniteScrollList";
import ProdukListVM from "../models/produkList";

export default defineComponent({
  name: "ProdukList",
  components: {
    Box: () => import("@/apps/core/components/Box.vue"),
    ProdukListBox: () => import("@/apps/produk/components/ProdukListBox.vue"),
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const listVM = new ProdukListVM();
    const composition = useInfiniteScrollList(listVM);
    return {
      // Composition
      ...composition,
    };
  },
});
