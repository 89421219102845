import { API } from "@/apps/core/api";
import axios from "axios";
import PlainModel from "@/apps/core/models/plainModel";
import ViewModel from "@/apps/core/models/viewModel";


class Produk extends PlainModel {
  label: string | null = null;
  nama: string | null = null;
  bunga: string | null = null;
  tenor: string | null = null;
  maksimalPinjaman: string | null = null;
  deskripsi: string | null = null;
  url: string | null = null;
}

class ProdukAPI extends API {
  static modelPath = "/produk/";

  constructor() {
    super(ProdukAPI.modelPath);
  }

  async fetch(): Promise<Record<string, any>> {
    const url = this.getAPIUrl();
    const response = await axios.get(url);
    // error akan di-catch di viewmodel / form!!!
    return response.data;
  }
}

export default class ProdukVM extends ViewModel {
  constructor() {
    super(new ProdukAPI(), new Produk());
  }
}


export { Produk, ProdukAPI };