import ListAPI from "@/apps/core/api/list";
import { Produk, ProdukAPI } from "./produk";
import ViewListModel from "@/apps/core/models/viewListModel";

class ProdukListAPI extends ListAPI {
  constructor() {
    super(ProdukAPI.modelPath);
  }
}

class ProdukListVM extends ViewListModel {
  constructor() {
    super(new ProdukListAPI());
  }

  getInstance(json: Record<string, any>): Produk {
    const produk = new Produk();
    produk.loadData(json);
    return produk;
  }
}

export { ProdukListAPI };
export default ProdukListVM;
